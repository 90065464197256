import React, { useState, useEffect } from 'react';
import '../css/opening.css';
import '../css/general.css';

const Notifications = () => {
  const url = `${window.baseDomain}restaurants/admin-notifications/`;
  const updateDeleteUrl = `${window.baseDomain}restaurants/admin-notifications/update-delete/`;
  const [notifications, setNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedDayNumber, setSelectedDayNumber] = useState('');

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: getAuthHeaders(),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch notifications');
      }

      const data = await response.json();
      const formattedNotifications = data.map(notification => ({
        id: notification.id,
        notification_text: notification.notification_text,
        rules: notification.rules,
      }));
      
      setNotifications(formattedNotifications);
    } catch (error) {
      console.error('Error fetching notifications:', error.message);
    }
  };

  const getAuthHeaders = () => {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'JWT ' + localStorage.getItem('authKey'),
    };
  };

  const addNotification = async () => {
    if (newNotification) {
      let rules = {
        month: selectedMonth ? `_m${selectedMonth}` : '',
        days: selectedDays.length > 0 ? `_${selectedDays.join('')}` : '',
        dayNumber: selectedDayNumber ? `_day${selectedDayNumber}` : '',
      };

      const rulesString = Object.values(rules).filter(rule => rule).join('');

      const data = {
        rules: rulesString || "/",
        notification_text: newNotification,
      };

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: getAuthHeaders(),
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error('Failed to post notification');
        }

        const newNotificationData = await response.json();
        const formattedNotification = {
          id: newNotificationData.id,
          notification_text: newNotificationData.notification_text,
          rules: newNotificationData.rules,
        };
        
        setNotifications([...notifications, formattedNotification]);

        setNewNotification('');
        setSelectedMonth('');
        setSelectedDays([]);
        setSelectedDayNumber('');
        setShowModal(false);
      } catch (error) {
        console.error('Error posting notification:', error.message);
      }
    }
  };

  const removeNotification = async (notificationId) => {
    try {
      const endpoint = `${updateDeleteUrl}${notificationId}/`;
      const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: getAuthHeaders(),
      });

      if (!response.ok) {
        throw new Error('Failed to delete notification');
      }

      setNotifications(notifications.filter(notification => notification.id !== notificationId));
    } catch (error) {
      console.error('Error deleting notification:', error.message);
    }
  };

  const decodeRules = (rules) => {
	const monthNames = ["", "Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];
	const dayNames = ["", "Ma", "Di", "Wo", "Do", "Vr", "Za", "Zo"];
	
	let decodedRules = {
	  months: [],
	  weekDays: [],
	  specificDays: []
	};
  
	if (rules) {
	  // Look for literal matches for m10, m11, and m12 first 
	  const specificMonthMatches = rules.match(/_m(10|11|12)/g);
	  if (specificMonthMatches) {
		decodedRules.months.push(...specificMonthMatches.map(month => monthNames[parseInt(month.slice(2), 10)]));
	  }
  
	  // Match single-digit months (m1 to m9) only if not part of a two-digit match
	  const singleDigitMonthMatches = rules.match(/_m([1-9])(?!\d)/g); // Ensures no digit follows the match
	  if (singleDigitMonthMatches) {
		decodedRules.months.push(...singleDigitMonthMatches.map(month => monthNames[parseInt(month.slice(2), 10)]));
	  }
  
	  // Match days of the week (d1 to d7)
	  const dayMatches = rules.match(/d[1-7](?!\d)/g); // Ensures no digit follows the match
	  if (dayMatches) {
		decodedRules.weekDays.push(...dayMatches.map(day => dayNames[parseInt(day.slice(1), 10)]));
	  }
  
	  // Match specific day numbers (like _day3, _day15)
	  const dayNumberMatches = rules.match(/_day(\d{1,2})/g);
	  if (dayNumberMatches) {
		decodedRules.specificDays.push(...dayNumberMatches.map(day => `Dag ${parseInt(day.slice(4), 10)}`));
	  }
	}
  
	return decodedRules;
  };  

  const displayNotifications = notifications.map((notification) => {
    const decoded = decodeRules(notification.rules);
    const displayDescription = (
      <span>
        {notification.notification_text}
        {decoded && (
          <span style={{ color: 'gray' }}>
            {decoded.months.length > 0 && ` - ${decoded.months.join(', ')}`}
            {decoded.weekDays.length > 0 && ` - ${decoded.weekDays.join(', ')}`}
            {decoded.specificDays.length > 0 && ` - ${decoded.specificDays.join(', ')}`}
          </span>
        )}
      </span>
    );

    return (
      <li key={notification.id} className="list-group-item d-flex justify-content-between align-items-center">
        <div>{displayDescription}</div>
        <div>
          <button className="btn btn-danger btn-sm" onClick={() => removeNotification(notification.id)}>Wissen</button>
        </div>
      </li>
    );
  });

  return (
    <div className="container">
      <h1>Mededelingen</h1>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <label htmlFor="newNotification" className="form-label">Voeg een mededeling toe</label>
      </div>
      <div className="mb-3">
        <div className="input-group">
          <input 
            type="text" 
            className="form-control" 
            id="newNotification" 
            value={newNotification} 
            onChange={(e) => setNewNotification(e.target.value)}
            placeholder="Bvb. herinnering, waarschuwing, update"
          />
          <button className="btn btn-primary" onClick={() => setShowModal(true)}>Toevoegen</button>
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">Mededelingen</label>
        <ul className="list-group">
          {displayNotifications}
        </ul>
      </div>

      {showModal && (
        <div className="custom-alert" tabIndex="-1" role="dialog">
          <div className="custom-alert-content" style={{textAlign:"left"}} role="document">
            <h5 className="modal-title" style={{ marginBottom: '10px' }}>Selecteer maand, dagen en dagnummer (optioneel)</h5>
            <div className="form-group mb-3">
              <label className="mb-3" htmlFor="monthSelect">Maand</label>
              <select 
                id="monthSelect" 
                className="form-control" 
                value={selectedMonth} 
                onChange={(e) => setSelectedMonth(e.target.value)}
              >
                <option value="">Alle</option>
                <option value="1">Januari</option>
                <option value="2">Februari</option>
                <option value="3">Maart</option>
                <option value="4">April</option>
                <option value="5">Mei</option>
                <option value="6">Juni</option>
                <option value="7">Juli</option>
                <option value="8">Augustus</option>
                <option value="9">September</option>
                <option value="10">Oktober</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </div>
            <div className="form-group mt-3 mb-3">
              <label className="mt-3 mb-3">Dagen van de week</label>
              <div className="d-flex flex-wrap">
                {['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'].map((day, index) => (
                  <div key={index} className="form-check form-check-inline">
                    <input 
                      className="form-check-input text-black" 
                      type="checkbox" 
                      id={`day-${index}`} 
                      value={"d" + (index + 1)} 
                      onChange={(e) => {
                        const day = e.target.value;
                        setSelectedDays(prev => 
                          e.target.checked ? [...prev, day] : prev.filter(d => d !== day)
                        );
                      }}
                    />
                    <label className="form-check-label text-black" htmlFor={`day-${index}`}>{day}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="form-group mt-3 mb-3">
              <label className="mb-3" htmlFor="dayNumberSelect">Dagnummer (optioneel)</label>
              <input
                type="number"
                id="dayNumberSelect"
                className="form-control"
                value={selectedDayNumber}
                onChange={(e) => setSelectedDayNumber(e.target.value)}
                placeholder="Bvb. 15"
                min="1"
                max="31"
              />
            </div>
            <div className="modal-footer" style={{ marginTop: '15px' }}>
              <button style={{ marginRight: '15px' }} type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Sluiten</button>
              <button type="button" className="btn btn-primary" onClick={addNotification}>Opslaan</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;
