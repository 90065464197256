import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Row, Col, Badge } from 'react-bootstrap';
import { FaStar, FaRegStar } from 'react-icons/fa';
import releases from './releases';
import ReleasesSection from './release_section';
import './releases.css';

const monthsInDutch = [
  'januari', 'februari', 'maart', 'april', 'mei', 'juni',
  'juli', 'augustus', 'september', 'oktober', 'november', 'december'
];

const formatDateToDutch = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = monthsInDutch[date.getMonth()];
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

const isThisWeek = (dateString) => {
  const releaseDate = new Date(dateString);
  const now = new Date();
  const oneWeekAgo = new Date(now);
  oneWeekAgo.setDate(now.getDate() - 7);
  return releaseDate >= oneWeekAgo && releaseDate <= now;
};

const UpdateReleases = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedRelease, setExpandedRelease] = useState(null);
  const [starredReleases, setStarredReleases] = useState(() => {
    const savedStars = JSON.parse(localStorage.getItem('starredReleases')) || [];
    return new Set(savedStars);
  });
  const [showStarredOnly, setShowStarredOnly] = useState(false);

  useEffect(() => {
    const savedStars = JSON.parse(localStorage.getItem('starredReleases')) || [];
    setStarredReleases(new Set(savedStars));
  }, []);

  useEffect(() => {
    localStorage.setItem('starredReleases', JSON.stringify(Array.from(starredReleases)));
    window.starred = new Set(starredReleases);
  }, [starredReleases]);

  const toggleStar = (id) => {
    setStarredReleases(prevStars => {
      const newStars = new Set(prevStars);
      if (newStars.has(id)) {
        newStars.delete(id);
      } else {
        newStars.add(id);
      }
      return newStars;
    });
  };

  const toggleReadMore = (id) => {
    setExpandedRelease(expandedRelease === id ? null : id);
  };

  const now = new Date();
  const pastReleases = releases.filter(release => release.date !== 'future' && new Date(release.date) <= now);
  const futureReleases = releases.filter(release => release.date === 'future');

  const filteredPastReleases = pastReleases.filter(release => {
    const matchesSearchTerm = release.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      release.description.toLowerCase().includes(searchTerm.toLowerCase());
    const isStarred = starredReleases.has(release.id);
    return matchesSearchTerm && (!showStarredOnly || isStarred);
  });

  const filteredFutureReleases = futureReleases.filter(release => {
    const matchesSearchTerm = release.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      release.description.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesSearchTerm;
  });

  return (
    <Container>
      <h1>Updates</h1>
      <div className="mb-4 d-flex align-items-center">
        <Form.Control
          type="text"
          placeholder="Zoek releases..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ height: '48px' }}
        />
        <div className="ms-3 d-flex align-items-center justify-content-center" style={{ border: '1px solid #ccc', borderRadius: '5px', height: '48px', width: '48px' }}>
          <Button variant="link" onClick={() => setShowStarredOnly(!showStarredOnly)} style={{ fontSize: '1.5rem', height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <FaStar color={showStarredOnly ? 'gold' : 'grey'} />
          </Button>
        </div>
      </div>
      <div style={{ marginBottom: '2rem', marginTop: '40px' }}>
        <ReleasesSection
          title="Huidige Releases"
          releases={filteredPastReleases}
          expandedRelease={expandedRelease}
          toggleReadMore={toggleReadMore}
          toggleStar={toggleStar}
          starredReleases={starredReleases}
        />
      </div>
      <div style={{ marginBottom: '2rem' }}>
        <ReleasesSection
          title="Toekomstige Releases"
          releases={filteredFutureReleases}
          expandedRelease={expandedRelease}
          toggleReadMore={toggleReadMore}
          toggleStar={toggleStar}
          starredReleases={starredReleases}
        />
      </div>
    </Container>
  );
};

export default UpdateReleases;