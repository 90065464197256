import React, { useEffect } from 'react';

const ClosingTabs = ({
  dates,
  activeTab,
  onTabChange,
  setClosedDaysCount,
  currentMonthIndex,
  nextMonthIndex,
  nextNextMonthIndex,
  closedDaysCount,
  months,
}) => {
  const calculateClosedDaysCount = () => {
    const uniqueDates = new Set();
    const count = { current: 0, next: 0, nextNext: 0, all: 0 };

    dates.forEach((dateObj) => {
      if (dateObj.end_date) {
        const start = new Date(dateObj.date);
        const end = new Date(dateObj.end_date);

        for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
          const dateString = d.toISOString().split('T')[0];
          if (!uniqueDates.has(dateString)) {
            uniqueDates.add(dateString);
            const month = d.getMonth();

            if (month === currentMonthIndex) count.current += 1;
            if (month === nextMonthIndex) count.next += 1;
            if (month === nextNextMonthIndex) count.nextNext += 1;

            count.all += 1;
          }
        }
      } else {
        const date = new Date(dateObj.date);
        const dateString = date.toISOString().split('T')[0];

        if (!uniqueDates.has(dateString)) {
          uniqueDates.add(dateString);
          const month = date.getMonth();

          if (month === currentMonthIndex) count.current += 1;
          if (month === nextMonthIndex) count.next += 1;
          if (month === nextNextMonthIndex) count.nextNext += 1;

          count.all += 1;
        }
      }
    });

    setClosedDaysCount(count);
  };

  useEffect(() => {
    calculateClosedDaysCount();
  }, [dates]);

  const renderCount = (count) => (count > 0 ? `(${count})` : '');

  return (
    <div className="custom-tabs mt-6">
      <div 
        className={`custom-tab ${activeTab === 'current' ? 'active' : ''}`} 
        onClick={() => onTabChange('current')}
      >
        {months[currentMonthIndex]} {renderCount(closedDaysCount.current)}
      </div>
      <div 
        className={`custom-tab ${activeTab === 'next' ? 'active' : ''}`} 
        onClick={() => onTabChange('next')}
      >
        {months[nextMonthIndex]} {renderCount(closedDaysCount.next)}
      </div>
      <div 
        className={`custom-tab ${activeTab === 'nextNext' ? 'active' : ''}`} 
        onClick={() => onTabChange('nextNext')}
      >
        {months[nextNextMonthIndex]} {renderCount(closedDaysCount.nextNext)}
      </div>
      <div 
        className={`custom-tab ${activeTab === 'all' ? 'active' : ''}`} 
        onClick={() => onTabChange('all')}
      >
        Alle Maanden {renderCount(closedDaysCount.all)}
      </div>
    </div>
  );
};

export default ClosingTabs;
