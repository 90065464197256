const releases = [
	{
	  id: 1,
	  title: 'Mateza 3.5.1 - Update Overzicht',
	  description: 'Toevoeging van releasekaarten voor een betere weergave van updates.',
	  date: '2024-10-04'
	},
	{
	  id: 2,
	  title: 'Widget',
	  description: 'Toekomstige widget voor eenvoudige boekingen.',
	  date: 'future'
	},
	{
		id: 3,
		title: 'Shiften',
		description: 'Managen en Aanmaak Shifts.',
		date: 'future'
	  },
  ];
  
  export default releases;