import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col, Badge, Pagination } from 'react-bootstrap';
import { FaStar, FaRegStar } from 'react-icons/fa';

const monthsInDutch = [
  'januari', 'februari', 'maart', 'april', 'mei', 'juni',
  'juli', 'augustus', 'september', 'oktober', 'november', 'december'
];

const formatDateToDutch = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  if (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  ) {
    return 'Vandaag';
  }
  const day = date.getDate();
  const month = monthsInDutch[date.getMonth()];
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

const isThisWeek = (dateString) => {
  const releaseDate = new Date(dateString);
  const now = new Date();
  const oneWeekAgo = new Date(now);
  oneWeekAgo.setDate(now.getDate() - 7);
  return releaseDate >= oneWeekAgo && releaseDate <= now;
};

const isToday = (dateString) => {
  const releaseDate = new Date(dateString);
  const now = new Date();
  return (
    releaseDate.getDate() === now.getDate() &&
    releaseDate.getMonth() === now.getMonth() &&
    releaseDate.getFullYear() === now.getFullYear()
  );
};

const ReleasesSection = ({ title, releases, expandedRelease, toggleReadMore, toggleStar, starredReleases }) => {
  const sortedReleases = [...releases].sort((a, b) => {
    if (a.date === 'future') return 1;
    if (b.date === 'future') return -1;
    return new Date(a.date) - new Date(b.date);
  });

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const releasesPerPage = 5;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  // Get current releases
  const indexOfLastRelease = currentPage * releasesPerPage;
  const indexOfFirstRelease = indexOfLastRelease - releasesPerPage;
  const currentReleases = sortedReleases.slice(indexOfFirstRelease, indexOfLastRelease);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return (
      <div>
        <h2 style={{ marginBottom: '1rem' }}>{title}</h2>
        <Row>
          {[...Array(3)].map((_, idx) => (
            <Col key={idx} sm={12} md={12} lg={12} className="mb-4">
              <Card className="h-100">
                <Card.Body>
                  <div className="placeholder-glow" style={{ borderRadius: '0.5rem' }}>
                    <h2 className="placeholder col-6 rounded"></h2>
                    <div className="placeholder col-7 rounded mb-3"></div>
                    <p className="placeholder col-4 rounded mb-3"></p>
                    <p className="placeholder col-8 rounded mb-3"></p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  }

  return (
    <div>
      <h2 style={{ marginBottom: '1rem' }}>{title}</h2>
      <Row>
        {currentReleases.map((release) => (
          <Col key={release.id} sm={12} md={12} lg={12} className="mb-4">
            <Card className="h-100">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <h2>
                    {release.title} {release.date !== 'future' && <small className="text-muted" style={{ color: '#0d6efd' }}>{formatDateToDutch(release.date)}</small>}
                    {release.date !== 'future' && isToday(release.date) && (
                      <Badge bg="secondary" className="ms-2">NIEUW</Badge>
                    )}
                  </h2>
                  <Button
                    variant="link"
                    onClick={() => toggleStar(release.id)}
                    className={starredReleases.has(release.id) ? 'rainbowStar' : ''}
                    style={{ fontSize: '1.5rem' }}
                  >
                    {starredReleases.has(release.id) ? <FaStar color="gold" /> : <FaRegStar />}
                  </Button>
                </div>
                <p>
                  {expandedRelease === release.id
                    ? release.description
                    : release.description.length > 100
                    ? `${release.description.substring(0, 100)}... `
                    : release.description}
                  {release.description.length > 100 && (
                    <Button variant="link" onClick={() => toggleReadMore(release.id)} style={{ marginLeft: '8px' }}>
                      {expandedRelease === release.id ? 'Lees Minder' : 'Lees Meer...'}
                    </Button>
                  )}
                </p>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Pagination className="mt-4">
        {[...Array(Math.ceil(releases.length / releasesPerPage)).keys()].map(number => (
          <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
            {number + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  );
};

export default ReleasesSection;
