import React, { useRef, useEffect } from 'react';
import './css/login.css';
import videoBackground from '../assets/video.webm'; // Add your video file here
import logoImage from '../assets/logo.webp';
import thibaultPicture from '../assets/profile.webp'; // Add your second logo file here
 // Add your second logo file here
import './css/login.css';
import {runScriptForCanvas} from '../utils/animation'; // Assuming you've moved the WebGL script to a separate file.

const Login = () => {
  const canvasRef1 = useRef(null);
  const canvasRef2 = useRef(null);
  let canvasSleeping1 = true;
  let canvasSleeping2 = true;

  const handleSubmit = (username, password, suffix) => {
      fetch(window.baseDomain + 'auth/jwt/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.access) {
            const currentDate = new Date().toISOString().split('T')[0];
            localStorage.setItem('loginDate', currentDate);
            localStorage.setItem('restaurantName', username);
            localStorage.setItem('authKey', data.access);
            localStorage.setItem('refreshKey', data.refresh);

            const logoUpdateEvent = new CustomEvent('logoUpdate');
            window.dispatchEvent(logoUpdateEvent);
            window.location.href = window.currentDomain + suffix;
          } else {
            console.error('Error: Invalid credentials');
          }
        })
        .catch((error) => {
          localStorage.setItem('login', 'failure');
          console.error('Error:', error);
        });
    };

  useEffect(() => {
    localStorage.removeItem('authKey');
    localStorage.removeItem('refreshKey');

    const form = document.getElementById('signInForm');
    form.addEventListener('submit', (event) => {
      event.preventDefault();
      const username = document.getElementById('username').value;
      const password = document.getElementById('password').value;

      window.username = username;
      handleSubmit(username, password, "");
    });

    const appWrapper = document.getElementById('kt_app_wrapper');
    const appHeaderContainer = document.getElementById('kt_app_header_container');
    if (appWrapper) appWrapper.classList.add('hidden');
    if (appHeaderContainer) appHeaderContainer.classList.add('hidden');

    return () => {
      form.removeEventListener('submit', handleSubmit);
      if (appWrapper) appWrapper.classList.remove('hidden');
      if (appHeaderContainer) appHeaderContainer.classList.remove('hidden');
    };
  }, []);

  return (
    <div className="container-fluid mateza-container">

      <a href="https://mateza.be">
        <div alt="Logo Mateza" className="logo-mateza"></div>
      </a>

      <div className="row vh-100">
      <div className="col-md-6 d-none d-md-flex left-section flex-column justify-content-center align-items-center position-relative">
      <video
        className="video-background"
        src={videoBackground}
        autoPlay
        loop
        muted
      />
      <a href="https://mateza.be">
        <img
          src={logoImage}
          alt="Logo"
          className="logo img-fluid mb-4"
        />
      </a>
      <h1 className="text-center">Omdat U Beter Verdient</h1>
      <div className="text-center" style={{ display: 'flex', alignItems: 'center', width: "90%" }}>
        <p>
          Ik ben Thibault Van de Sompele (23) en betaalbare IT voor ieder restaurant is mijn missie. 
        </p>
        
      </div>
    
          <img
            src={thibaultPicture}
            alt="Thibault's profile"
            className="profile-pic mt-1"
            style={{ opacity: 0.9, borderRadius: "100px", width: '40px', height: '40px', objectFit: 'cover' }} 
          />

        <div className="text-center mt-3" style={{ color: "#E87B90", opacity: 1, fontWeight: 600, position: "absolute", bottom: "20px", alignItems: 'center', width: "90%", maxWidth: "400px", display: 'flex', justifyContent: 'space-around' }}>
          <a href="https://mateza.be/onderzoek" target="_blank" style={{ color: "#E87B90"}}>Goede Doelen</a> |
          <a href="https://mateza.be/klimaat" target="_blank" style={{ color: "#E87B90" }}>Klimaat</a> |
          <a href="https://mateza.be/mateza-websites" target="_blank" style={{ color: "#E87B90" }}>Website Service</a>
        </div>



      
    </div>

        <div className="col-md-6 right-section d-flex justify-content-center align-items-center position-relative">
          <form className="sign-in-form w-75" id="signInForm">
            <h2 className="text-center mb-4">Hartelijk Welkom!</h2>
            <div className="form-group">
              <label htmlFor="username">Gebruikersnaam</label>
              <input type="text" className="form-control" id="username" placeholder="Voer je gebruikersnaam in" />
            </div>
            <div className="form-group">
              <label htmlFor="password">Wachtwoord</label>
              <input type="password" className="form-control" id="password" placeholder="Voer je wachtwoord in" />
            </div>

            <button type="submit"
            className="btn-animated m-1 btn btn-form btn-primary btn-block"
            style={{ position: "relative", zIndex: 1 }}
            onMouseEnter={() => {
              if (canvasSleeping1) {
                const canvas = canvasRef1.current;
                runScriptForCanvas(canvas);
                canvasSleeping1 = false
              }
            }}
          >
            <canvas ref={canvasRef1} style={{ width: '100%', height: '100%'}} />
            Aanmelden
          </button>
          <button type="button"
            className="btn-animated btn-form btn-secondary btn-block mt-2"
            style={{ position: "relative", zIndex: 1 }}
            onMouseEnter={() => {
              
              if (canvasSleeping2) {
                const canvas = canvasRef2.current;
                runScriptForCanvas(canvas);
                canvasSleeping2 = false
              }
              
            }}
            onClick={() => window.location.href = 'https://mateza.fillout.com/t/qaPQFU5gYWus'}
          >
            <canvas ref={canvasRef2} style={{ width: '100%', height: '100%'}} />
            Over Ons
          </button>

            <p className="text-center mt-6">
              Vragen? <a href="https://mateza.net/contact">Neem contact</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
