import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GradientSelector from './pages/layout/gradient/index.js';
import Reservaties from './pages/overzicht/month/index.js';
import Closing from './pages/instellingen/closing/index.js';
import AnalyticsNav from './pages/analytics/nav.js';
import Analytics from './pages/analytics/analytics.js';
import Graph from './pages/analytics/graph.js';
import Feedback from './pages/instellingen/feedback/index.js';
import Settings from './pages/hidden/settings.js';
import Opening from './pages/openingsuren/nav.js';
import Ontbijt from './pages/openingsuren/ontbijt.js';
import Lunch from './pages/openingsuren/lunch.js';
import Diner from './pages/openingsuren/diner.js';
import Login from './pages/login.js';
import Phone from './pages/phone.js'
import Delen from './pages/delen/index.js';
import HelpNav from './pages/help/index.js'
import SettingsNav from './pages/instellingen/nav.js';
import Day from './pages/overzicht/day/index.js';
import Week from './pages/overzicht/week/index.js';
import Nieuw from './pages/nieuw/index.js';
import TableEdit from './pages/instellingen/table_edit/index.js';
import TableSlots from './pages/overzicht/table_view/index.js';
import TableLabel from './pages/instellingen/table_label/index.js';
import MenuOption from './pages/instellingen/menu.js';
import Giftcard from './pages/giftcards/giftcard.js';
import Event from './pages/hidden/event.js';
import Orders from './pages/giftcards/orders.js';
import ImageUploader from './pages/layout/logo/index.js'
import ProfilePictureUploader from './pages/layout/profile/index.js'
import ReservationSummary from './pages/nieuw/summary.js';
import handleRefreshToken from './utils/handleRefreshToken.js';
import ExceptionForm from './pages/instellingen/exceptions.js';
import Notifications from './pages/instellingen/notifications.js';
import LayoutNav from './pages/layout/nav.js';
import DayWait from './pages/overzicht/day_wait/index.js';
import ProfileDetail from './pages/overzicht/day/profile.js';
import UpdateReleases from './pages/updates/index.js';

const App = () => {
  const [currentPage, setCurrentPage] = useState("reservaties");


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/phone" element={<Phone />} />
        <Route path="/*" element={<MainApp currentPage={currentPage} onPageChange={handlePageChange} />} />
      </Routes>
    </Router>
  );
};

const MainApp = ({ currentPage, onPageChange, pageState }) => {
  useEffect(() => {
    const handleClick = (e) => {
      const target = e.currentTarget.getAttribute('data-page');
      onPageChange(target);

      links.forEach(link => {
        if (link.getAttribute('data-page') === target) {
          link.classList.add('active');
        } else {
          link.classList.remove('active');
        }
      });
    };

    // NPS Surveys
    window.SurveyWidget.init({
      type: "default",
      delay: 5,
      include_unsubscribe: true,
      survey_id: "fc0e2f54-c187-471a-a8e7-37f0beb5d493",
      survey_collector_id: "SC_-1",
      contact: {
        "work_email": localStorage.getItem('restaurantName') + "@mateza.be", // Dynamic email from username
      }
    });

    const links = document.querySelectorAll('.menu-link');
    links.forEach(link => {
      link.addEventListener('click', handleClick);
    });

    const linksNoStyle = document.querySelectorAll('.menu-link-no-style');
    linksNoStyle.forEach(linkNoStyle => {
      linkNoStyle.addEventListener('click', handleClick);
    });

    return () => {
      links.forEach(link => {
        link.removeEventListener('click', handleClick);
      });
      linksNoStyle.forEach(link => {
        link.removeEventListener('click', handleClick);
      });
    };      
  }, [onPageChange]);

  if (localStorage.getItem('refreshKey')) {
      setInterval(() => {
        handleRefreshToken().catch((error) => {
          console.error('Failed to refresh token:', error);
        });
      }, 300000);
    } else {
      return (<Login />);
    }

  const currentDate = new Date().toISOString().split('T')[0];
  if (localStorage.getItem('loginDate') !== currentDate) {
    return (<Login />);
  }

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  switch (currentPage) {
    case "waiting-today":
      return <DayWait key={`today-${today.getTime()}`} name="Vandaag" endpoint={formatDate(today)} />;
    case "orders":
      return <Orders />;
    case "help-nav":
      return <HelpNav />;
    case "delen":
      return <Delen />;
    case "notifications":
      return <Notifications />;
    case "gradient":
      return <GradientSelector />;
    case "reservaties":
      return <Reservaties onPageChange={onPageChange} />;
    case "closing":
      return <Closing />;
    case "profile-detail":
      return <ProfileDetail />
    case "analytics":
      return <div className="container"><Analytics /></div>;
    case "graph":
      return <Graph />;
    case "feedback":
      return <Feedback />;
    case "opening":
      return <Opening onPageChange={onPageChange} />;
    case "ontbijt":
      return <Ontbijt />;
    case "lunch":
      return <Lunch />;
    case "diner":
      return <Diner />;
    case "settings":
      return <Settings />;
    case "nieuw":
        return <Nieuw onPageChange={onPageChange} />;
    case "week":
        return <Week />;
    case "unlog":
        return <Login />
    case "tables":
      return <TableEdit />;
    case "tableslots":
      return <TableSlots />;
    case "tablelabel":
      return <TableLabel />;
    case "menuoptions":
      return <MenuOption />;
    case "updates":
      return <UpdateReleases />;
    case "giftcards":
      return <Giftcard />;
    case "analytics-nav":
      return <AnalyticsNav onPageChange={onPageChange}  />;
    case "settings-nav":
      return <SettingsNav onPageChange={onPageChange}  />
    case "layout-nav":
      return <LayoutNav onPageChange={onPageChange}  />
    case "event":
      return <Event />;
    case "logo":
      return <ImageUploader/>
    case "exceptions":
      return <ExceptionForm/>
    case "profile":
      return <ProfilePictureUploader/>
    case "today":
      return <Day key={`today-${today.getTime()}`} name="Vandaag" endpoint={formatDate(today)} onPageChange={onPageChange} />;
    case "reservation-summary":
      return <ReservationSummary state={pageState} />;
    default:
      return <Reservaties onPageChange={onPageChange} />;
  }
};

export default App;
