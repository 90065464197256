import React, { useState, useEffect } from 'react';
import useDataRetriever from '../../../utils/useDataRetriever';
import ClosingTabs from './tabs';
import MonthTable from './months';
import DateRangeHandler from './range';
import '../../css/opening.css';
import '../../css/general.css';
import moment from 'moment'; 

const Closing = () => {
  const url = `${window.baseDomain}restaurants/closing-dates/`;
  const { data, error, loading } = useDataRetriever(url);
  const [dates, setDates] = useState([]);
  const [newDate, setNewDate] = useState('');
  const [activeTab, setActiveTab] = useState('current');
  const [closedDaysCount, setClosedDaysCount] = useState({ current: 0, next: 0, nextNext: 0, all: 0 });
  const [uniqueClosedDays, setUniqueClosedDays] = useState(new Set());

  const daysOfWeek = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
  const months = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];

  const currentDate = new Date().toISOString().split('T')[0];
  const maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 18);
  const maxDateString = maxDate.toISOString().split('T')[0];

  const today = new Date();
  const currentMonthIndex = today.getMonth();
  const nextMonthIndex = (currentMonthIndex + 1) % 12;
  const nextNextMonthIndex = (currentMonthIndex + 2) % 12;

  // Log data received from useDataRetriever
  console.log('Initial Data from useDataRetriever:', data);

  useEffect(() => {
    if (data && data.length > 0) {
      // Log data processing step
      console.log('Processing Data from useDataRetriever:', data);

      const closingDates = data.map(item => ({
        id: item.id,
        date: item.date,
        end_date: item.end_date || null,
      }));

      // Log the processed data before setting state
      console.log('Processed Closing Dates:', closingDates);

      setDates(closingDates);
    }
  }, [data]);

  useEffect(() => {
    calculateUniqueClosedDays();
  }, [dates]);



const calculateUniqueClosedDays = () => {
  const uniqueDays = new Set();

  dates.forEach((dateObj) => {
    console.log('Processing Date Object:', dateObj);

    if (dateObj.end_date) {
      const start = moment(dateObj.date);
      const end = moment(dateObj.end_date);

      console.log(`Calculating range from ${start.format('YYYY-MM-DD')} to ${end.format('YYYY-MM-DD')}`);

      let currentDate = moment(start);
      while (currentDate.isSameOrBefore(end)) {
        const dateString = currentDate.format('YYYY-MM-DD');
        console.log(`Adding date to unique days: ${dateString}`);
        uniqueDays.add(dateString);

        currentDate = currentDate.add(1, 'days');
        console.log(`Next date to process: ${currentDate.format('YYYY-MM-DD')}`);
      }
    } else {
      console.log(`Adding single date to unique days: ${dateObj.date}`);
      uniqueDays.add(dateObj.date);
    }
  });

  setUniqueClosedDays(uniqueDays);
};

  
  

  const getAuthHeaders = () => {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'JWT ' + localStorage.getItem('authKey'),
    };
  };

  const postDate = async (payload) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to post date');
      }

      const newDate = await response.json();

      setDates((prevDates) => [...prevDates, newDate]);
    } catch (error) {
      console.error(`Failed to post date: ${error.message}`);
    }
  };

  const addDate = async () => {
    console.log('Adding Date:', newDate);
    if (newDate && !dates.some(date => date.date === newDate)) {
      const payload = { date: newDate };
      await postDate(payload);
      setNewDate('');
    }
  };

  const addRangeToDates = async (range) => {
    console.log('Adding Date Range:', range);
    await postDate(range);
  };

  const deleteDate = async (id) => {
    console.log('Deleting Date ID:', id);
    const endpoint = `${url}${id}/`;

    try {
      await fetch(endpoint, {
        method: 'DELETE',
        headers: getAuthHeaders(),
      });
    } catch (error) {
      console.error(`Failed to delete date ${id}: ${error.message}`);
    }
  };

  const removeDate = async (id) => {
    console.log('Removing Date ID from State:', id);
    setDates((prevDates) => {
      const updatedDates = prevDates.filter(date => date.id !== id);
      console.log('Updated Dates After Removal:', updatedDates);
      return updatedDates;
    });

    try {
      await deleteDate(id);
    } catch (error) {
      console.error(`Failed to delete date ${id}: ${error.message}`);
    }
  };

  const handleTabChange = (tab) => {
    console.log('Changing Active Tab:', tab);
    setActiveTab(tab);
  };

  const formatDutchDate = (dateString) => {
    const date = new Date(dateString);
    const day = daysOfWeek[date.getDay()];
    const dayNumber = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${dayNumber} ${month} ${year}`;
  };

  const filterDatesByTab = (date) => {
    const monthIndex = new Date(date.date).getMonth();
    const startMonthIndex = new Date(date.date).getMonth();
    const endMonthIndex = date.end_date ? new Date(date.end_date).getMonth() : null;

    switch (activeTab) {
      case 'current':
        return monthIndex === currentMonthIndex || (startMonthIndex <= currentMonthIndex && endMonthIndex >= currentMonthIndex);
      case 'next':
        return monthIndex === nextMonthIndex || (startMonthIndex <= nextMonthIndex && endMonthIndex >= nextMonthIndex);
      case 'nextNext':
        return monthIndex === nextNextMonthIndex || (startMonthIndex <= nextNextMonthIndex && endMonthIndex >= nextNextMonthIndex);
      case 'all':
      default:
        return true;
    }
  };

  let monthIndexToDisplay = currentMonthIndex;
  if (activeTab === 'next') {
    monthIndexToDisplay = nextMonthIndex;
  } else if (activeTab === 'nextNext') {
    monthIndexToDisplay = nextNextMonthIndex;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container">
      <h1>Sluitingsdagen</h1>

      <div className="mb-3">
        <label htmlFor="newDate" className="form-label">Voeg sluitingsdag toe</label>
        <div className="input-group">
          <input 
            type="date" 
            className="form-control" 
            id="newDate" 
            value={newDate} 
            min={currentDate} 
            max={maxDateString}
            onChange={(e) => setNewDate(e.target.value)}
          />
          <button className="btn btn-primary" onClick={addDate}>Toevoegen</button>
        </div>
      </div>

      <DateRangeHandler addRangeToDates={addRangeToDates} currentDate={currentDate} maxDateString={maxDateString} />

      <ClosingTabs
        dates={dates}
        activeTab={activeTab}
        onTabChange={handleTabChange}
        setClosedDaysCount={setClosedDaysCount}
        currentMonthIndex={currentMonthIndex}
        nextMonthIndex={nextMonthIndex}
        nextNextMonthIndex={nextNextMonthIndex}
        closedDaysCount={closedDaysCount}
        months={months}
      />

      <div className="row mt-4">
        <div className={`col-md-${activeTab === 'all' ? '12' : '6'}`}>
          <ul className="list-group">
            {dates
              .filter(filterDatesByTab)
              .map((date, index) => (
                <li key={date.id} className="list-group-item d-flex justify-content-between align-items-center">
                  {date.end_date ? `${formatDutchDate(date.date)} - ${formatDutchDate(date.end_date)}` : formatDutchDate(date.date)}
                  <button className="btn btn-danger btn-sm" onClick={() => removeDate(date.id)}>Verwijderen</button>
                </li>
              ))}
          </ul>
        </div>

        {activeTab !== 'all' && (
          <div className="col-md-6">
            <MonthTable 
              dates={dates} 
              monthIndex={monthIndexToDisplay} 
              year={today.getFullYear()} 
              closedDays={Array.from(uniqueClosedDays)} // Pass unique closed days as an array
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Closing;
