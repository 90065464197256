import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { startOfDay, startOfWeek, startOfMonth, startOfYear, endOfDay, endOfWeek, endOfMonth, endOfYear, isWithinInterval, format } from "date-fns";
import { FaHome } from 'react-icons/fa';
import './map.css';
import ViewModeButtons from './map_buttons';
import useDataRetriever from '../../../utils/useDataRetriever';
import { Form } from 'react-bootstrap';

const belgiumCoordinates = [50.8503, 4.3517];

const MIcon = new L.Icon({
  iconUrl: "https://static.reservaties.net/images/logo/logo.png",
  iconSize: [30, 30],
  iconAnchor: [15, 15],
  popupAnchor: [0, -15],
});

const BackToBelgiumButton = () => {
  const map = useMap();

  const moveToBelgium = () => {
    map.setView(belgiumCoordinates, 7);
  };

  return (
    <div className="back-to-belgium" onClick={moveToBelgium}>
      <FaHome className="fa-lg" />
    </div>
  );
};

const LocationMap = () => {
  const [viewMode, setViewMode] = useState("day");
  const [dateRangeText, setDateRangeText] = useState("");
  const [filterBy, setFilterBy] = useState("date");
  const [filterByVerb, setFilterByVerb] = useState("");

  // Fetch data from the API using useDataRetriever
  const { data, error, loading } = useDataRetriever(window.baseDomain + "reservations/map-view/");

  // Determine the date range based on the viewMode (day, week, month, year)
  const today = new Date();
  const getDateRange = () => {
    if (viewMode === "day") {
      return { start: startOfDay(today), end: endOfDay(today) };
    } else if (viewMode === "week") {
      return { start: startOfWeek(today, { weekStartsOn: 1 }), end: endOfWeek(today, { weekStartsOn: 1 }) };
    } else if (viewMode === "month") {
      return { start: startOfMonth(today), end: endOfMonth(today) };
    } else if (viewMode === "year") {
      return { start: startOfYear(today), end: endOfYear(today) };
    }
  };

  const { start, end } = getDateRange();

  useEffect(() => {
    // Update date range text whenever viewMode changes
    const formattedStart = format(start, "dd MMM yyyy");
    const formattedEnd = format(end, "dd MMM yyyy");
    setDateRangeText(`Reservaties ${filterByVerb} van ${formattedStart} tot ${formattedEnd}`);
  }, [viewMode, start, end, filterBy]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data!</div>;

  // Filter data points based on the selected date range and skip reservations with null loc or city
  const filteredDataPoints = data?.filter((point) => {
    try {
      if (!point.loc || !point.city) {
        return false; // Skip if loc or city is null
      }
      return isWithinInterval(new Date(point[filterBy]), { start, end });
    } catch (err) {
      return false; // Skip this point in case of an error
    }
  }) || [];


  // Sum guests by city
  const guestsByCity = filteredDataPoints.reduce((acc, point) => {
    const city = point.city;
    if (!acc[city]) {
      acc[city] = point.guests;
    } else {
      acc[city] += point.guests;
    }
    return acc;
  }, {});


  // Convert 'loc' string into coordinates array for markers
  const getCoordinatesFromLoc = (locString) => {
    try {
      const [lat, lon] = locString.split(',').map(coord => parseFloat(coord));
      return [lat, lon];
    } catch (err) {
      return [0, 0]; // Return default coordinates in case of error
    }
  };

  return (
    <div className="container-fluid mt-4">
      <h1>Reservatiemap</h1>
      <div style={{ marginTop: '-20px' }} className="row">
        <div className="col-md-12 position-relative">
          <MapContainer
            className="mt-10"
            center={belgiumCoordinates}
            zoom={7}
            style={{ height: "600px", width: "100%" }}
          >
            <TileLayer
              attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
            />

            {/* Render markers for each unique loc */}
            {filteredDataPoints.map((point, index) => (
              <Marker
                key={index}
                position={getCoordinatesFromLoc(point.loc)} // Convert 'loc' to coordinates
                icon={MIcon}
              >
                <Popup>
                  <strong>{point.city}</strong> <br />
                  Totaal gasten vanuit {point.city}: {guestsByCity[point.city]}
                </Popup>
              </Marker>
            ))}
            <BackToBelgiumButton />
          </MapContainer>
          <ViewModeButtons viewMode={viewMode} setViewMode={setViewMode} />
          {/* Date range and filter switch */}
          <div className="top-right-controls">
            <div className="date-range-text">
              {dateRangeText}
            </div>
            <Form>
              <Form.Check 
                type="switch"
                id="custom-switch"
                label={`Filter op aanmaak datum.`}
                checked={filterBy === "created_at"}
                onChange={() => {
                  setFilterBy(filterBy === "created_at" ? "date" : "created_at");
                  setFilterByVerb(filterByVerb === "" ? "aangemaakt" : "")}}
              />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationMap;
