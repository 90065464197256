import React, { useState } from 'react';
import { endOfMonth, format, parseISO } from 'date-fns';
import { nl } from 'date-fns/locale';

const DateRangeHandler = ({ addRangeToDates, currentDate, maxDateString }) => {
  const [rangeStart, setRangeStart] = useState('');
  const [rangeEnd, setRangeEnd] = useState('');

  // Helper function to format a date object to 'YYYY-MM-DD' string
  const formatDate = (date) => format(date, 'yyyy-MM-dd');

  // Helper function to format date object to 'dd MMMM yyyy' in Dutch
  const formatDutchDate = (date) => format(date, 'dd MMMM yyyy', { locale: nl });

  const handleAddDateRange = async () => {
    if (rangeStart && rangeEnd && rangeStart <= rangeEnd) {
      let currentStart = parseISO(rangeStart);
      const end = parseISO(rangeEnd);


      while (currentStart <= end) {

        // Calculate end of the current month
        const endOfCurrentMonth = endOfMonth(currentStart);

        // Determine subrange end date
        // If end of the month is earlier than the overall end date, use end of the month; otherwise, use the provided end date.
        const subRangeEndDate = endOfCurrentMonth < end ? endOfCurrentMonth : end;

        // Prepare payload
        // Only include `end_date` in the payload if the start and end date are different
        const payload = {
          date: formatDate(currentStart), // The start date for the subrange
          ...(currentStart.getTime() !== subRangeEndDate.getTime() && {
            end_date: formatDate(subRangeEndDate), // The end date for the subrange, if different from the start date
          }),
        };

        await addRangeToDates(payload); // Send the subrange to be added

        // Move to the first day of the next month
        currentStart = new Date(currentStart.getFullYear(), currentStart.getMonth() + 1, 1);
      }

      setRangeStart('');
      setRangeEnd('');
    }
  };

  return (
    <div className="mt-4 mb-3">
      <label htmlFor="rangeStart" className="form-label">Voeg een reeks sluitingsdagen toe</label>
      <div className="input-group">
        <input 
          type="date" 
          className="form-control" 
          id="rangeStart" 
          value={rangeStart} 
          min={currentDate} 
          max={maxDateString}
          onChange={(e) => setRangeStart(e.target.value)}
        />
        <span className="input-group-text">tot</span>
        <input 
          type="date" 
          className="form-control" 
          id="rangeEnd" 
          value={rangeEnd} 
          min={rangeStart || currentDate} 
          max={maxDateString}
          onChange={(e) => setRangeEnd(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleAddDateRange}>Toevoegen</button>
      </div>
    </div>
  );
};

export default DateRangeHandler;
