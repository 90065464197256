import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../../css/opening.css';
import '../../css/general.css';
import './months.css';

const MonthTable = ({ dates, monthIndex, year, closedDays }) => {
  // Get the first day of the month and the number of days in the month
  const firstDay = new Date(year, monthIndex, 1).getDay(); // Get the weekday of the first day
  const daysInMonth = new Date(year, monthIndex + 1, 0).getDate(); // Get the number of days in the month

  // Function to format date in Dutch
  const formatDutchDate = (day) => {
    const date = new Date(year, monthIndex, day);
    const dayNames = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
    const monthNames = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];
    return `${dayNames[date.getDay()]} ${day} ${monthNames[monthIndex]} ${year}`;
  };

  // Determine the number of rows needed for the calendar
  const rows = Math.ceil((daysInMonth + firstDay) / 7);

  // Create the calendar grid
  const calendar = [];
  let dayCount = 1;

  for (let i = 0; i < rows; i++) {
    const week = [];
    for (let j = 0; j < 7; j++) {
      if (i === 0 && j < firstDay) {
        // Empty cells before the start of the month
        week.push(
          <td key={`empty-${i}-${j}`} className="empty-cell">
            <OverlayTrigger
              key={`tooltip-empty-${i}-${j}`}
              placement="top"
              overlay={<Tooltip id={`tooltip-empty-${i}-${j}`}>No date</Tooltip>}
            >
              <span>&nbsp;</span>
            </OverlayTrigger>
          </td>
        );
      } else if (dayCount <= daysInMonth) {
        const dayDate = `${year}-${String(monthIndex + 1).padStart(2, '0')}-${String(dayCount).padStart(2, '0')}`;
        const isClosed = closedDays.includes(dayDate); // Check using closedDays prop


        week.push(
          <td
            key={`day-${i}-${j}`}
            className={isClosed ? 'table-danger text-center' : 'table-success text-center'}
          >
            <OverlayTrigger
              key={`tooltip-${dayDate}`}
              placement="top"
              overlay={<Tooltip id={`tooltip-${dayDate}`}>{formatDutchDate(dayCount)}</Tooltip>}
            >
              <div className="day-circle">{dayCount}</div>
            </OverlayTrigger>
          </td>
        );
        dayCount++;
      } else {
        // Empty cells after the end of the month
        week.push(
          <td key={`empty-${i}-${j}`} className="empty-cell">
            <OverlayTrigger
              key={`tooltip-empty-${i}-${j}`}
              placement="top"
              overlay={<Tooltip id={`tooltip-empty-${i}-${j}`}>No date</Tooltip>}
            >
              <span>&nbsp;</span>
            </OverlayTrigger>
          </td>
        );
      }
    }
    calendar.push(<tr key={`week-${i}`}>{week}</tr>);
  }


  return (
    <table className="closing-table month-table">
      <thead>
        <tr>
          <th className="text-center">Ma</th>
          <th className="text-center">Di</th>
          <th className="text-center">Wo</th>
          <th className="text-center">Do</th>
          <th className="text-center">Vr</th>
          <th className="text-center">Za</th>
          <th className="text-center">Zo</th>
        </tr>
      </thead>
      <tbody>{calendar}</tbody>
    </table>
  );
};

export default MonthTable;
